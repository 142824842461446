.account-form {
  display: flex;

  &__inputs {
    display: flex;

    &-account {
      width: 340px;
      padding-right: 40px;

      .selectbox {
        margin-bottom: 15px;

        &.light {
          .selectbox__header {
            height: 40px;
            line-height: 20px;
          }
        }
      }

      .textbox {
        .textbox__input:disabled  {
          /* stylelint-disable declaration-no-important */
          border-bottom: 1px solid $color-gray-extended-2 !important;
        }
      }
    }

    &-contact {
      width: 292px;
      padding: 0 40px;
      border-right: 1px solid $screen-background-1;
      border-left: 1px solid $screen-background-1;
    }

    &-address {
      width: 200px;
      margin-left: 40px;
    }
  }

  &__subtitles {
    margin-bottom: 15px;
    font-family: $font-family-medium;
    font-size: 18px;
  }

  &__state {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 1fr 35%;

    .textbox {
      margin-bottom: 0;
    }
  }
}
