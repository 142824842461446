//primary colors
$color-primary-black: #000;
$color-primary-white: #FFF;
$color-primary-red: #E00;

$color-secondary-orange: #ED7000;
$color-secondary-yellow: #FFBC3D;
$color-secondary-yellow-calendar: #FFC963;
$color-secondary-green: #00AC3E;
$color-secondary-blue: #0088CE;
$color-secondary-pink: #FF47F6;

$color-secondary-blue-80: #339FD7;
$color-secondary-blue-40: #99CFEB;
$color-secondary-blue-5: #F2F9FD;

//grays
$color-gray-extended-1: #F6F6F6;
$color-gray-extended-2: #D8DADA;
$color-gray-extended-3: #AFAFAF;
$color-gray-extended-4: #747676;
$color-gray-extended-5: #535353;
$color-gray-extended-6: #424242;
$color-gray-extended-7: #333;
$color-gray-extended-8: #2E2E2E;

$screen-background-1: #EFEFEF;

//modal, loader
$backdrop-modal: rgba(0, 0, 0, .7);
$backdrop-loader: rgba(0, 0, 0, .5);
$fallback-backdop: $color-gray-extended-8;

//table
$color-table-border: #535353;
$color-table-header-bg: #333;
$color-table-header-text: #AFAFAF;
$color-table-cell-bg: #424242;
$color-table-cell-text: #FFF;

$color-table-light-border: $color-gray-extended-7;
$color-table-light-header-bg: $color-gray-extended-1;
$color-table-light-header-text: $color-primary-black;
$color-table-light-cell-bg: $color-primary-white;
$color-table-light-cell-text: $color-primary-black;
$color-table-light-cell-border: $color-gray-extended-2;

$color-table-green: #CCEED8;
$color-table-green-darker: #99DEB1;
$color-table-orange: #FCE2CC;
$color-table-orange-darker: #F8C699;

// timeline
$color-timeline-pointline-yellow: #FFBC3D99;
$color-timeline-pointline-yellow-light: #FFBC3D36;

// sass-lint:disable no-invalid-hex
$color-list-shadow-box: #00000026;
$color-dropdown-shadow-box: #0000004D;

// schedule
$color-schedule-playbar-bg: #4D4D4D;
$color-schedule-tooltip-separator: #E2E2E2;
$color-schedule-ghost-dimmingpoint-bg: #F6FAFC;

// edit modal separator
$color-modal-separator: #EEE;

// map colors
$color-map-bg-light: #EEE;
$color-map-bg-hybrid: #666;
$color-map-bg-dark: #333;

/* stylelint-disable color-hex-length */
$color-map-search-bg: #00000099;

/* alarms - status */
$color-alarms-major: #FFF824;
$color-alarms-minor: #3DF8FF;
$color-alarms-critical: $color-secondary-orange;
