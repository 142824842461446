.node-diagnostics {
  button {
    width:110px;
    margin: 10px 40px;
  }
  h2 {
    font-size:16px;
    font-weight:bold;
  }
  .textbox__input {
    width:200px;
  }
  &-imei {
    position:absolute;
    top:30px;
    left:300px;
    width: 400px;
  }
  &-static, &-external {
    width:200px;
  }
  &-realtime {
    position:absolute;
    top:30px;
    left:600px;
    width:200px;
  }
  &-cancel {
    position:absolute;
    top:258px;
    left:740px;
    width:200px;
  }
  &-result {
    position:relative;
    top:300px;
    width:100%;
    padding:12px;
    height:calc( 100vh - 400px );
    overflow-y: scroll;
    overflow-x: scroll;
    border:1px solid gray;
  }
}
