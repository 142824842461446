.upload-confirm {
  &__content {
    span {
      display: block;
      font-size: 14px;
      line-height: 16px;

      &:first-of-type {
        margin-bottom: 15px;
      }
    }
  }
}

.custom-attributes {
  .tabletoolbar__title.width-subtitle {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .tabletoolbar__children {
    .btn-secondary {
      border: 1px solid $color-primary-white;
    }
  }

  .table {
    .table__data-cell,
    .tooltip > div {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
