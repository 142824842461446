/* stylelint-disable max-nesting-depth */
.container {
  .page-content {
    &.users-content {
      margin: 20px 25px 50px 30px;

      .checkbox__button {
        background: $color-primary-white;

        svg {
          fill: $color-primary-black;
        }
      }

      .textbox {
        input {
          background: $color-primary-white;
        }
      }

      .toolbar-container {
        margin-bottom: 15px;

        .users-title {
          margin-right: 0;
          margin-left: 0;
        }

        .margin-right-auto {
          margin-right: auto;
        }

        .selectbox {
          line-height: 25px;

          &.light {
            .selectbox__header {
              height: 25px;
              padding: 0 15px 0 0;
              border: 0 none;
              border-bottom: 1px solid $color-primary-black;
              background: $screen-background-1;
              font-family: $font-family-medium;
              line-height: 25px;

              svg {
                right: 0;
              }
            }

            ul {
              width: 120px;
              max-width: auto;
              border-top: 1px solid $color-primary-black;
              background: $color-primary-white;

              &.open {
                position: absolute;
                top: 31px;
              }
            }
          }
        }

        .btn {
          margin-left: 20px;
        }

        .search-partner-and-account {
          display: flex;
          margin-left: 30px;

          .search-partner {
            width: 220px;
            height: 25px;
            margin-right: 20px;
            background-color: transparent;

            .search__icon {
              top: unset;
              right: 0;
              bottom: 10px;
              width: 12px;
              cursor: pointer;
              stroke: $color-primary-black;
            }

            .search__input {
              position: relative;
              height: 25px;
              padding-right: 15px;
              border-bottom: 1px solid $color-primary-black;
              color: $color-primary-black;
              font-family: $font-family-medium;
              line-height: 25px;

              &::placeholder {
                color: $color-primary-black;
              }
            }

            .ReactVirtualized__Grid {
              position: absolute;
              top: 25px;
              width: 178px;
              border: 1px solid $color-gray-extended-5;
              background: $color-primary-white;
              z-index: 5;

              .search-partner--list {
                display: flex;
                align-items: center;
                border-top: 1px solid $color-gray-extended-5;
                color: $color-primary-black;
                line-height: 40px;
                cursor: pointer;

                span {
                  color: $color-primary-black;
                  font-size: 14px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}

.users-mfa {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 15px;

  span {
    margin-bottom: 5px;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 17px;
  }

  .mfa-content {
    display: flex;
    flex-direction: row;

    span {
      margin: 12px 0 0 5px;
      font-family: $font-family-roman;
    }
  }

  .checkbox {
    margin-top: 10px;

    .checkbox__button {
      border: 1px solid $color-primary-black;
      background: $color-primary-white;

      svg {
        fill: $color-primary-black;
      }
    }
  }
}

.usermanager-actions {
  svg {
    margin-right: 15px;
    cursor: pointer;
  }
}

.select-partner-account {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid $color-gray-extended-2;

  .item {
    width: 265px;
    color: $color-gray-extended-2;
    font-family: $font-family-medium;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
}

.users.config__content {
  .edit-user,
  .create-user {
    .modal {
      .selectbox {
        margin-top: 20px;
      }
    }
  }

  .modal {
    .selectbox {
      ul {
        top: 61px;
      }
    }

    .modal__content {
      padding-bottom: 20px;
    }

    &.admin-user-modal {
      color: $color-primary-white;

      .modal__content {
        padding-bottom: 20px;
      }

      .admin-user-modal-content {
        display: flex;
        align-items: center;
        font-size: 14px;

        &-icon {
          margin-right: 5px;
          fill: $color-secondary-yellow;
        }
      }

      .btn-primary {
        color: $color-primary-white;
      }

      &.create-api {
        line-height: 16px;
        white-space: normal;

        .admin-user-modal-content {
          align-items: start;
        }
      }
    }
  }
}
