.wizard-modal {
  padding: 0;
  transform: translate(-50%, 0);

  .energy-node-selector {
    display: flex;
    flex-direction: column;
    width: 180px;
    min-width: 180px;
    height: 347px;
    margin-right: 0;
    background-color: $color-primary-white;

    .site-info {
      display: grid;
      align-items: center;
      justify-content: flex-start;
      width: 364px;
      height: 60px;
      margin-bottom: 40px;
      background-color: $color-gray-extended-1;

      svg {
        width: 24px;
        height: 24px;
        margin: 10px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column: 1;
        fill: $color-gray-extended-4;
      }

      &__title {
        align-self: flex-end;
        font-family: $font-family-bold;
        font-size: 12px;
        line-height: 14px;
        grid-row: 1;
        grid-column: 2;
      }

      &__subtitle {
        align-self: flex-start;
        font-family: $font-family-roman;
        font-size: 14px;
        line-height: 16px;
        grid-row: 2;
        grid-column: 2;
      }
    }

    &--individual-node {
      width: 324px;
      padding: 0 40px 0 0;

      .site-info {
        width: 284px;
        margin-bottom: 40px;
      }
    }

    &--group-selector {
      width: 404px;
      padding-left: 40px;

      .group-info {
        display: grid;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          grid-row: 1;
          grid-column: 1;
          fill: $color-primary-black;
        }

        &__title {
          align-self: center;
          font-family: $font-family-bold;
          font-size: 14px;
          line-height: 16px;
          grid-row: 1;
          grid-column: 2;

          &.required:after {
            color: $color-primary-red;
            content: '*';
          }
        }
      }

      .group-actions__container-group {
        position: relative;
        top: 0;
        width: 364px;
        padding: 0;
        border: 1px solid $color-primary-black;
        background-color: $color-primary-white;

        &-list {
          padding-bottom: 0;

          .group-list-row {
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid $color-gray-extended-2;
            color: $color-primary-black;
            line-height: 40px;
            cursor: pointer;

            &--selected {
              padding-left: 6px;
              border-left: 4px solid $color-primary-red;
            }

            &__group-name {
              color: $color-primary-black;
              font-size: 14px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &__node-length {
              margin-left: auto;
              color: $color-primary-black;
            }
          }
        }
      }
    }
  }

  .energy-summary {
    display: flex;
    flex-direction: column;

    &__table {
      display: grid;
      justify-content: flex-start;
      white-space: pre;
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      &-label {
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: $font-family-medium;
        font-size: 12px;
        line-height: 15px;
        grid-column: 1 / 2;
        justify-self: start;
        text-transform: lowercase;

        &:first-letter {
          text-transform: capitalize;
        }
      }

      &-field {
        width: 100%;
        margin-bottom: 10px;
        font-family: $font-family-roman;
        font-size: 12px;
        line-height: 14px;
        grid-column: 2 / 3;
        justify-self: start;

        span {
          display: inline-block;
          margin-bottom: 5px;
        }
      }
    }

    &__input {
      align-self: flex-end;
      margin-bottom: 20px;
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      .textbox {
        &.error {
          span {
            margin-bottom: -11px;
          }
        }
      }
    }

    &__divided-content {
      display: flex;
      flex-direction: row;

      &-left,
      &-right {
        display: grid;
        background-color: $color-primary-white;
        white-space: pre;
      }

      &-right {
        width: 275px;
        padding: 0;
        padding-left: 20px;
      }

      &-left {
        width: 311px;
        border: 0;

        .energy-summary__table {
          border-right: 1px solid $color-gray-extended-2;
          grid-template-columns: 90px 200px;
        }

        .energy-summary__input {
          padding-right: 20px;
        }
      }
    }
  }

  .wizard-modal-divided {
    display: flex;
    flex-direction: row;
    height: 100%;

    &__sidebar {
      width: 170px;
      margin-right: 0;
      background-color: $color-gray-extended-1;
    }

    &__content {
      display: flex;
      flex-direction: column;
      min-width: 324px;
      height: 100%;
      padding: 25px 30px 30px 20px;
      color: $color-primary-black;

      .column-selector {
        display: grid;
        min-width: 324px;
        padding-top: 0;
        padding-right: 0;
        grid-template-columns: 145px auto;
        grid-template-rows: 42px 40px auto;

        .__instruction {
          grid-row: 1 / 2;
          grid-column: 1 / 3;
        }

        .columns {
          display: grid;
          grid-template-rows: repeat(6, 30px);

          div {
            display: grid;
            grid-template-columns: 30px auto;
          }

          &--all {
            display: grid;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }

          &--general {
            display: grid;
            grid-column: 1 / 2;
            grid-row: 3 / 4;
          }

          &--node {
            display: grid;
            grid-column: 2 / 3;
            grid-row: 3 / 4;
          }

          &--disabled {
            span {
              color: $color-gray-extended-2;
            }
          }

          span {
            font-size: 14px;
            line-height: 20px;
            grid-column: 2 / 3;
          }

          .checkbox--light {
            align-content: unset;
            grid-column: 1 / 2;
          }
        }
      }

      .date-period-selector {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding-right: 40px;
        background-color: $color-primary-white;

        &--individual-node {
          width: 230px;
          padding: 0 0 0 10px;
        }

        .react-datepicker-container {
          .react-datepicker__label--above {
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .column-selector,
      .energy-summary,
      .date-period-selector,
      .recipient-editor,
      .energy-node-selector {
        &__instruction {
          margin-bottom: 20px;
          font-family: $font-family-bold;
          font-size: 18px;
          line-height: 20px;
          white-space: nowrap;
        }

        &__radio-title {
          align-self: flex-start;
          margin-bottom: 10px;
          font-family: $font-family-medium;
          font-size: 14px;
          line-height: 16px;
          grid-row: 1;
          grid-column: 2;

          &.required:after {
            color: $color-primary-red;
            content: '*';
          }
        }

        .radio-buttons__element {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal__title {
        width: 100%;
        color: $color-primary-black;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: left;

        &-main {
          font-family: $font-family-roman;
        }

        &-sub {
          font-family: $font-family-bold;
        }
      }

      .modal-divided__sidebar {
        border-right: 1px solid $color-gray-extended-2;
      }

      .modal__footer {
        width: 100%;
        margin-top: auto;

        .btn-primary:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .report-navigation-bar {
    position: relative;
    margin: 30px 20px;
    font-size: 14px;
    list-style: none;

    &__label {
      position: absolute;
      top: 3px;
      left: 30px;
      width: 100px;
      color: $color-primary-black;
      font-family: $font-family-bold;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
    }

    li {
      display: grid;
      position: relative;
      width: 20px;
      height: 20px;
      margin-top: 60px;
      border-radius: 20px;
      background: $color-primary-red;
      color: $color-primary-white;
      font-family: $font-family-bold;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;

      &.long-text {
        .report-navigation-bar__label {
          top: -5px;
        }
      }

      &:before {
        position: absolute;
        top: -60px;
        left: 8px;
        width: 4px;
        height: 60px;
        background: $color-primary-red;
        content: '';
      }

      &:first-child {
        margin-top: 0;

        &:before {
          display: none;
        }
      }

      &.active {
        &:not(.last-visited) {
          border: 1px solid $color-primary-red;
          background: $color-primary-white;
          color: $color-primary-red;
          line-height: 18px;

          &:before {
            top: -61px;
            left: 7px;
            height: 61px;
          }

          .report-navigation-bar__label {
            top: 2px;
            left: 29px;
          }

          &.long-text {
            .report-navigation-bar__label {
              top: -6px;
            }
          }
        }
      }
    }

    .last-visited {
      & ~ li {
        left: 5px;
        width: 10px;
        height: 10px;
        margin-top: 70px;
        border-radius: 10px;
        background: $color-gray-extended-2;
        line-height: 10px;
        cursor: default;

        span {
          display: none;
        }

        &:before {
          top: -70px;
          left: 3px;
          height: 70px;
          background: $color-gray-extended-2;
          cursor: default;
        }

        .report-navigation-bar__label {
          top: -3px;
        }

        &.long-text {
          .report-navigation-bar__label {
            top: -10px;
          }
        }

        div {
          left: 25px;
          font-family: $font-family-roman;
        }
      }

      & + li {
        margin-top: 65px;

        &:before {
          top: -65px;
          height: 65px;
        }
      }
    }
  }
}
