$commissioning-toolbar-border: 1px solid $color-gray-extended-5;
$commissioning-toolbar-height: 55px;

.commissioning {
  .page-content {
    margin: 20px 30px 50px;
  }

  .commissioning-actions {
    display: flex;
    width: 100%;

    .tooltip__element {
      display: flex;
      align-items: center;
      height: 38px;
      margin-top: 50%;
      transform: translate(0, -25%);
    }

    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-family: $font-family-roman;
    font-size: 12px;
    line-height: 14px;

    &--element {
      margin-right: 20px;

      &-val {
        margin-left: 3px;
        color: $color-secondary-yellow;
        font-family: $font-family-bold;
      }
    }

    &--actions {
      display: flex;
      height: $commissioning-toolbar-height;
      border-left: $commissioning-toolbar-border;

      &-btn {
        position: relative;
        height: 100%;
        padding-top: 3px;
        padding-right: 20px;
        padding-left: 20px;
        border: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        outline: 0;
        background: $color-gray-extended-7;

        &:first-child {
          &:after {
            display: block;
            position: absolute;
            top: 10px;
            right: -1px;
            width: 1px;
            height: calc(#{$commissioning-toolbar-height} - 23px);
            background: $color-gray-extended-5;
            content: '';
            z-index: 10;
          }

          &.selected {
            &:after {
              background: transparent;
            }
          }
        }

        &.commissioning__info--actions-list {
          width: 30px;
          padding: 0;
          border-left: $commissioning-toolbar-border;

          svg {
            width: 3px;
          }
        }

        svg {
          height: 18px;
          fill: $color-primary-white;
        }

        &.selected {
          border-bottom: 3px solid $color-primary-red;
          background: $color-gray-extended-5;
        }
      }
    }
  }
}

.search-site__label {
  display: block;
  margin-bottom: 5px;
  font-family: $font-family-medium;
  font-size: 14px;
  line-height: 16px;
}

.actions-commission {
  position: relative;

  .dropdown {
    position: absolute;
    top: $commissioning-toolbar-height;
    right: -1px;
    width: 188px;
    padding: 0;
    box-shadow: 0 5px 10px $color-dropdown-shadow-box;
    z-index: 5;

    .actions-commission__element {
      display: flex;
      width: 100%;
      padding: 20px;
      border: 0;
      border-bottom: 1px solid $color-gray-extended-5;
      outline: 0;
      background: transparent;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;

      &:after {
        display: none;
      }

      &:last-child {
        border-bottom: 0;
      }

      svg {
        height: 18px;
        margin-right: 10px;
        fill: $color-primary-white;
      }

      &:hover {
        color: $color-gray-extended-2;
      }
    }
  }
}

.modal {
  &.commissioning {
    padding: 30px;

    .textbox {
      width: 100%;
      margin-bottom: 0;
    }

    .modal__content {
      flex-direction: column;
    }

    .commissioning__note {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &.auto-commission {
    padding: 30px;
    font-size: 14px;

    &.modal-full-height {

      .modal__footer {
        margin-top: auto;
      }
    }
  }

  &.delete-nodes {
    padding: 30px;

    .modal__content {
      flex-direction: column;
    }

    .delete-nodes__content {
      font-size: 14px;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &.download-list {
    .modal__content {
      flex-direction: column;
    }
  }
}

.add-nodes {
  font-size: 14px;
  line-height: 16px;

  span {
    display: block;
  }

  &__title {
    font-family: $font-family-medium;
  }

  &__content {
    margin-top: 10px;
  }
}
