.customize-fields {
  &.modal {
    padding: 30px;
    background: linear-gradient(90deg, $color-primary-white 50%, $color-gray-extended-1 50%);

    .modal__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .modal__title {
      margin-bottom: 25px;
    }

    .selectbox {
      width: 200px;

      ul {
        position: absolute;
        top: 26px;
        background-color: $color-primary-white;
      }

      &__header {
        width: 200px;
        height: 26px;
        padding-left: 0;
        border: none;
        border-bottom: 1px solid;
        background-color: transparent;

        svg {
          right: 0;
          width: 18px;
          height: 18px;
          stroke: none;
        }

        span {
          color: $color-primary-black;
          font-family: $font-family-bold;
        }
      }
    }
  }

  &__dnd-icon {
    display: flex;
    position: absolute;
    left: calc(50% - 35px);
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 71px;
    height: 71px;
    padding: 10px;
    background-color: $color-primary-white;
    color: $color-gray-extended-4;

    &-text {
      align-self: center;
      margin-top: 10px;
      font-size: 10px;
      line-height: 10px;
      white-space: nowrap;
    }

    svg {
      fill: $color-primary-black;
    }
  }

  &__empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 445px;
    margin-top: 40px;
    margin-left: 50px;
    border: 1px dashed $color-gray-extended-2;
    text-align: center;

    span {
      width: 178px;
      color: $color-gray-extended-2;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__list {
    width: 50%;

    &-info-text {
      display: flex;
      flex-direction: row;
      width: 240px;

      &-item {
        color: $color-primary-black;
        font-size: 10px;
        line-height: 12px;

        &--left {
          align-self: flex-end;
          margin-left: auto;
        }

        &-type {
          align-self: flex-end;
          margin-left: auto;
          color: $color-gray-extended-4;
        }
      }
    }

    &-toolbar {
      display: flex;
      justify-content: space-between;
      width: 240px;
      margin-bottom: 10px;
      margin-left: 50px;

      svg {
        fill: $color-primary-black;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    &-scrollable-box {
      width: 250px;
      min-height: 40px;
      max-height: 445px;
      margin-bottom: 5px;
      overflow: auto;

      &--left {
        margin-left: 50px;
      }

      .tooltip:last-child {
        .customize-fields__list-item {
          margin-bottom: 0;
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 240px;
      height: 40px;
      margin: 0 0 5px;
      font-size: 14px;
      line-height: 14px;

      &-type {
        align-self: center;
        margin-left: auto;
        color: $color-gray-extended-4;
        font-size: 12px;
        text-align: center;
      }
    }

    &-title {
      margin-bottom: 5px;
      color: $color-gray-extended-7;
      font-family: $font-family-bold;
      font-size: 18px;
    }

    &-subtitle {
      width: 250px;
      margin-bottom: 10px;
      color: $color-gray-extended-4;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
