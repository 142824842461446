.dropdown-groups-filter {
  position: absolute;
  top: 80px;
  width: 345px;
  padding: 20px 0;
  z-index: map-get($z-index, dropdown);

  .dropdown__primary-button,
  .dropdown__secondary-button {
    display: flex;
    align-items: flex-end;
    height: auto;
    padding: 0;
    border: 0;
    background: 0;
    color: $color-secondary-blue-80;
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
  }

  .dropdown__footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;

    .btn-secondary {
      margin: 0;
    }

    .btn-primary {
      margin-right: 5px;
    }
  }
}

.groups-filter {
  &__title {
    margin-right: auto;
    font-size: 18px;
    font-weight: bold;
  }

  &__tabs {
    display: flex;
    margin-bottom: 10px;
    padding-right: 25px;
    padding-left: 25px;
    border-bottom: 1px solid $color-gray-extended-5;

    button {
      display: flex;
      width: 145px;
      padding: 0;
      padding-bottom: 10px;
      border: 0;
      border-bottom: 2px solid transparent;
      background-color: transparent;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 18px;
      line-height: 20px;
      text-align: unset;
      white-space: nowrap;

      &.active {
        border-bottom: 2px solid $color-primary-red;
      }

      svg {
        width: 11px;
        margin-right: 10px;
        fill: $color-primary-white;
      }

      &:last-child {
        width: 116px;
        margin-left: 30px;

        svg {
          width: 18px;
        }
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &__list-row {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-7;
    line-height: 40px;
  }

  &__node-length {
    margin-left: auto;
    color: $color-secondary-yellow;
  }

  &__lighting-tab-content,
  &__org-tab-content {
    width: 100%;
    padding: 0 25px;
  }
}
