.info-element {
  padding: 10px;
  max-height: 280px;
  overflow-y: auto;

  button {
    border: 0;
    outline: none;
    background: transparent;
  }

  &--multi {
    align-items: flex-end;
    padding-right: 20px;
  }

  &-wrapper {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid $color-primary-black;
    height: 130px;
    margin-bottom: 5px;
  }

  &-container {
    width: 200px;
  }

  svg {
    align-self: center;
    fill: $color-primary-white;
  }

  &-letterhead {
    margin: 0 0 10px;

    svg {
      width: 14px;
      height: 11px;
      fill: $color-primary-white;
      stroke: $color-primary-white;

      &.minor {
        fill: $color-alarms-minor;
        stroke: $color-alarms-minor;
      }

      &.major {
        fill: $color-alarms-major;
        stroke: $color-alarms-major;
      }

      &.critical {
        fill: $color-secondary-orange;
        stroke: $color-secondary-orange;
      }
    }

    &--multi {
      width: 100%;
    }

    &__title {
      align-self: center;
      margin: 0 0 0 5px;
      color: $color-primary-white;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__id {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      color: $color-secondary-yellow;
      font-size: 10px;
      line-height: 12px;

      span {
        margin-right: 5px;
        color: $color-gray-extended-2;
        font-weight: bold;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    span {
      max-height: 44px;
      font-size: 14px;
      overflow: hidden;
    }
  }

  &__info {
    width: 125px;
    height: 80px;
    margin-left: auto;

    span {
      display: block;
      font-size: 10px;
      line-height: 12px;
    }

    &-updated {
      margin-top: 5px;
    }

    .info-element__date {
      color: $color-gray-extended-4;
    }

    &-title {
      color: $color-gray-extended-3;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    width: 40px;
    margin-top: 15px;

    button {
      padding: 0;

      svg {
        height: 14px;
      }
    }
  }
}
