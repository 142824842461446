.dimming-sequences {
  display: flex;
  align-items: center;
  height: 90px;
  background: $screen-background-1;
  overflow: hidden;

  &__title {
    width: 90px;
    min-width: 90px;
    margin: auto 30px;
    font-family: $font-family-bold;
    font-size: 18px;
    line-height: 20px;
  }

  .prev-arrow-container,
  .next-arrow-container {
    display: flex;
    position: absolute;
    align-items: center;
    width: 60px;
    height: 60px;

    > svg {
      width: 20px;
      stroke: $color-primary-black;
      cursor: pointer;

      &.disabled {
        stroke: $color-gray-extended-2;
        cursor: default;
      }
    }
  }

  .prev-arrow-container {
    left: 125px;

    &.gradient-bg {
      background: linear-gradient(to right, $screen-background-1 50%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .next-arrow-container {
    right: 55px;
    justify-content: flex-end;

    &.gradient-bg {
      background: linear-gradient(to left, $screen-background-1 50%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__content {
    display: flex;
    flex-wrap: nowrap;
    margin: auto 0;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &.scrollable {
      margin-right: 20px;

      &.readonly {
        margin-right: 80px;
      }
    }

    button {
      display: flex;

      &.disabled {
        cursor: default;
      }
    }

    .dimming-sequence {
      display: flex;
      flex: 0 0 auto;
      width: 220px;
      height: 60px;
      padding: 12px;
      padding-bottom: 9px;
      border: 1px solid $color-gray-extended-2;
      background: $color-primary-white;
      // scroll-snap-align: start;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.selected {
        border-bottom: 5px solid $color-primary-red;
      }

      &__content {
        cursor: pointer;

        &-title {
          display: flex;
          margin-bottom: 5px;

          &-text {
            display: inline-block;
            width: 150px;
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          svg {
            width: 14px;
            height: 14px;
            margin-right: 5px;

            &.unselected {
              fill: $color-gray-extended-2;
            }
          }
        }

        &-subtitle {
          font-family: $font-family-roman;
          font-size: 12px;
          line-height: 14px;
          text-transform: capitalize;

          &.no-days {
            color: $color-gray-extended-3;
          }
        }
      }

      &__delete {
        width: 24px;
        height: 32px;
        margin-left: auto;
        padding: 7px 0 7px 10px;
        border: 0;
        border-left: 1px solid $color-gray-extended-1;
        background: transparent;
      }

      &.disabled {
        .dimming-sequence__delete {
          fill: $color-gray-extended-2;
          cursor: default;
        }
      }
    }
  }

  > button {
    display: flex;
    align-self: center;
    min-width: 40px;
    min-height: 40px;
    margin: 0 10px;

    &.disabled {
      cursor: default;
    }
  }
}
