/* stylelint-disable max-nesting-depth */
.slide-in {
  position: relative;
  top: 0;
  bottom: 0;
  padding: 15px 20px;
  background-color: $color-gray-extended-7;
  color: $color-primary-white;
  overflow: hidden;
  z-index: 401;

  &.light {
    background: $color-gray-extended-6;
  }

  &__title {
    padding-bottom: 15px;
    border-bottom: 1px solid $color-gray-extended-4;

    > span {
      margin-left: 0;
      border: 0;
      font-family: $font-family-bold;
      font-size: 24px;
      white-space: nowrap;
    }

    &-subtitle {
      display: flex;
      padding: 10px 0 0;
      font-size: 12px;

      span {
        display: flex;
        width: auto;
        margin: 0 5px 0 0;
        font-family: $font-family-medium;
        font-size: 12px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &--expanded {
    background-color: $color-gray-extended-6;
    overflow: auto;

    .slide-in__title {
      margin-top: 4px;
      font-size: 16px;
    }
  }

  &__buttons {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      width: 15px;
      height: 15px;

      svg {
        height: 12px;
      }
    }
  }

  &__expand-button {
    display: flex;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    cursor: pointer;

    svg {
      align-self: center;
    }
  }

  &__expand-icon {
    fill: $color-primary-white;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .checkbox__button {
    border: 1px solid $color-gray-extended-3;
  }

  &__links {
    display: flex;
    justify-content: flex-end;

    .slide-in__link {
      margin-left: 20px;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 20px;
  }

  &__content {
    &--placeholder {
      margin-top: 40px;
      border-top: 1px solid $color-gray-extended-4;
    }

    &-title {
      margin-top: 15px;
      font-family: $font-family-medium;
      font-size: 18px;
      line-height: 20px;

      &--bigger-margin {
        margin-bottom: 15px;
      }
    }

    &-subtitle {
      margin: 5px 0;
      font-family: $font-family-roman;
      font-size: 12px;
      line-height: 14px;

      span {
        margin-right: 3px;
        font-family: $font-family-medium;
        white-space: nowrap;
      }
    }

    &-data {
      margin-top: 15px;

      &--title {
        margin-top: 25px;
        margin-bottom: 10px;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .table__header-cell {
      font-size: 14px;
    }

    .sort {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    &-info {
      display: flex;
      align-items: center;
      height: 14px;
      margin-top: 10px;
      color: $color-gray-extended-2;
      font-size: 12px;
      line-height: 14px;

      span {
        margin-right: 5px;
      }

      svg {
        width: 12px;
        height: 12px;
        margin-top: 5px;
        fill: $color-gray-extended-2;
      }
    }
  }

  &__secondary-title {
    margin-bottom: 15px;

    &.lights-id {
      font-family: $font-family-medium;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .sort {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  &__input-container {
    max-height: 495px;
    overflow-y: auto;

    input::placeholder {
      color: $color-gray-extended-4;
    }
  }

  .textbox {
    margin-bottom: 10px;
  }
}

// Specific style for LightsGroups slide-in

.groups {
  display: flex;

  .slide-in {
    .list-element {
      padding-top: 0;
      border-bottom: 0;
    }

    &__first-section {
      height: 105px;
      border-bottom: 1px solid $color-gray-extended-4;
    }

    &__subtitle {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 14px;

      &--num {
        padding: 0 3px;
        font-family: $font-family-medium;
      }

      &--bold {
        font-family: $font-family-medium;
      }
    }

    &--expanded {
      .slide-in__title {
        margin-top: 0;
        padding-bottom: 37px;
      }
    }

    .textbox {
      margin-bottom: 0;
    }

    .org-group-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      border: 1px solid $color-gray-extended-5;
      border-left: 3px solid transparent;
      line-height: 40px;

      &:before {
        display: block;
        position: absolute;
        left: -3px;
        width: 1px;
        height: 40px;
        border-right: 1px solid $color-gray-extended-5;
        content: '';
      }

      &:hover,
      &:active,
      &.selected {
        border-left: 3px solid $color-primary-red;

        svg {
          fill: $color-primary-white;
        }
      }

      &__icon {
        svg {
          cursor: pointer;
          fill: $color-gray-extended-4;
        }
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .lighting-group {
    &__modal {
      padding: 30px;

      &-content {
        display: flex;
        justify-content: space-between;
      }

      &-selected {
        align-self: center;
        font-size: 14px;
        line-height: 16px;
      }

      &-selected-node {
        margin-bottom: 5px;
        font-family: $font-family-medium;
      }

      .target-group {
        &-info {
          border: 1px solid $color-secondary-blue-40;
          background: $color-secondary-blue-5;

          &__title {
            display: flex;
            flex-direction: column;

            &-name {
              &.title {
                color: $color-primary-black;
                font-family: $font-family-medium;
              }

              &.groupname {
                color: $color-secondary-blue;
                font-family: $font-family-medium;
              }
            }
          }

          svg {
            fill: $color-secondary-blue-40;
          }
        }
      }
    }
  }

  .group-name {
    font-family: $font-family-bold;
  }
}

.title-width-link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .slide-in__title {
    display: flex;
    justify-content: center;
  }

  .slide-in__link {
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

// Specific style for LightsLocation slide-in

.location {
  display: flex;

  .slide-in__title {
    height: 62px;
    margin: 0;
  }

  .location-title__container {
    margin: 15px 0;

    &-title {
      margin: 15px 0 10px;
      font-family: $font-family-medium;
      font-size: 18px;
      line-height: 20px;
    }

    &-subtitle {
      margin-bottom: 10px;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__content-icon-container {
    display: flex;

    > span {
      width: 18px;
      margin-right: 10px;
      fill: $color-primary-white;
    }

    p {
      color: $color-gray-extended-2;
      font-family: $font-family-roman;
      font-size: 10px;
      line-height: 12px;
    }

    svg {
      cursor: pointer;
    }
  }

  .modal {
    &__subtitle {
      color: $color-primary-black;
      font-family: $font-family-roman;
      font-size: 14px;
    }

    &__footer {
      margin-top: 0;
    }

    &__primary-button {
      margin-left: 0;
    }
  }
}

.operation {
  display: flex;

  &__content {
    .list-element {
      .custom-val {
        svg {
          width: 18px;
          height: 18px;
          fill: $color-primary-white;
        }
      }
    }

    .slide-in__link {
      margin-top: 10px;

      .btn-textlink {
        color: $color-secondary-blue-80;
      }
    }

    .custom-list-element {
      margin-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-primary-black;

      &.fixture {
        margin-top: 10px;
        padding-bottom: 15px;
        border-color: $color-gray-extended-4;
      }

      .slide-in__link {
        margin-top: 5px;
        margin-bottom: 0;
      }

      &-subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-secondary-yellow;
        font-size: 14px;
        line-height: 16px;

        .slide-in__link {
          margin: 0;
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        &-name {
          width: 59px;
          font-family: $font-family-medium;
          font-size: 12px;
          line-height: 14px;
        }

        &-info {
          &-data {
            display: flex;
            justify-content: flex-end;
            font-size: 18px;
            line-height: 20px;

            span {
              font-family: $font-family-bold;
            }
          }

          svg {
            margin-left: 10px;
            font-size: 18px;
            line-height: 20px;
            fill: $color-primary-white;
          }
        }
      }
    }
  }

  .manual-override .dropdown__footer {
    border: 0;
  }
}

.power , .energy, .network {
  .custom-list-element {
    margin-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-primary-black;
  }
}

.sensors {
  border-bottom: none !important;

  .list-element .list-element-title {
    font-size: 12px;
  }

  svg.list-element__refresh {
    position: relative;
    top: -10px;
    float: right;
    height: 18px;
    transform: translate(0, -50%);
    fill: $color-primary-white;
    cursor: pointer;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% {  transform: rotate(359deg); }
    }

    &.refreshLoading {
      animation: spin 2s linear infinite;
      cursor: default;
      pointer-events: none;
      top: -20px;
    }
  }
}

.sidebar + .alarms {
  .slide-in {
    padding-right: 0;
  }

  .slide-in__title {
    padding-right: 20px;

    .alarms-title {
      display: flex;
      flex-direction: column;

      &__subtitle {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;

        .bold {
          margin-right: 5px;
          font-family: $font-family-medium;
        }
      }
    }
  }

  .slide-in__content {
    height: 100%;

    .no-alarms {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .modal {
    &__subtitle {
      margin-top: -25px;
    }

    &__primary-button {
      margin: 0;
    }

    .radio-buttons {
      flex-direction: column;
      justify-content: flex-start;
      margin-right: auto;

      &__element {
        margin-bottom: 10px;
      }
    }

    .textboxes {
      display: flex;
      flex-direction: column;

      label {
        color: $color-primary-black;
      }

      .selectbox__header {
        height: 40px;
      }

      &__timeboxes {
        display: flex;
        justify-content: space-between;
      }
    }

    .table--light {
      height: 60vh;

      .table__data-cell {
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

// Specific style for LigtsAlarmsSingle slide-in
.alarms {
  .slide-in__links {
    position: absolute;
    top: 15px;
    right: 20px;
  }
}

// Specific style for LightsOperationMulti slide-in
.operation-multinode {
  .slide-in__content {
    height: calc(100% - 80px);
  }

  .slide-in__title {
    border-bottom: 0;
  }

  .slide-in__link {
    margin-bottom: 0;
  }

  .plot-sensors {
    position: absolute;
    top: 84px;
    left: 138px;
    width: 302px;
    height: 500px;
    padding: 30px 50px;
    border: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-6;
    z-index: map-get($z-index, modal);

    &__close-button {
      position: absolute;
      top: 9px;
      right: 9px;

      svg {
        width: 16px;
        height: 16px;
        fill: $color-primary-white;
      }
    }

    .slide-in__link {
      margin-top: 0;
    }
  }

  .multi-node-modals {
    position: absolute;
    top: 84px;
    left: 138px;
    width: 302px;
    height: 500px;
    z-index: map-get($z-index, modal);

    .dropdown {
      height: 100%;
      padding: 30px 50px;
      background-color: $color-gray-extended-6;

      &__footer {
        justify-content: flex-end;
        margin-top: 0;
      }

      &__primary-button {
        height: 15px;
        padding: 0;
        border: 0;
        border-bottom: 1px solid $color-secondary-blue;
        border-radius: 0;
        color: $color-secondary-blue;
        font-size: 12px;
      }
    }

    .btn-secondary {
      height: auto;
      padding: 0;
      border: 0;
      border-bottom: 1px solid $color-secondary-blue-80;
      border-radius: 0;
      background: transparent;
      color: $color-secondary-blue-80;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .btn-textlink {
    border-bottom: 1px solid $color-secondary-blue-80;
    color: $color-secondary-blue-80;
  }

  .plot-sensors__close-button,
  .manual-override__close-button {
    border: 0;
    background: transparent;
  }

  input::placeholder {
    color: $color-gray-extended-4;
  }
}

.plot-sensors {
  &__title {
    margin-bottom: 10px;
    font-family: $font-family-bold;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
  }

  .selectbox {
    margin-bottom: 10px;

    &__label {
      color: $color-gray-extended-2;
      font-weight: bold;
    }

    &__header {
      svg {
        width: 12px;
        height: 7px;
      }
    }
  }

  .btn-textlink {
    color: $color-secondary-blue-80;
  }
}

.manual-override {
  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      width: 16px;
      height: 16px;
      fill: $color-primary-white;
    }
  }

  .dropdown {
    padding: 0;
    border: 0;
    background: transparent;

    &__title {
      margin-bottom: 10px;
    }

    &__subtitle {
      .small {
        font-size: 10px;
      }
    }

    &__content__data {
      display: flex;

      &-main {
        display: flex;

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          .textbox {
            width: 47px;
            margin-left: 5px;
          }
        }

        .textbox {
          margin-bottom: 10px;
          border-bottom: 1px solid $color-gray-extended-2;

          &__input {
            padding: 0 13px;

            &::placeholder {
              color: $color-gray-extended-4;
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-gray-extended-4;

      .btn-primary,
      .btn-secondary {
        height: auto;
        padding: 0;
        border: 0;
        border-bottom: 1px solid $color-secondary-blue-80;
        border-radius: 0;
        background: transparent;
        color: $color-secondary-blue-80;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .toolbar-action-dropdown {
    border-bottom: 1px solid $color-gray-extended-4;
  }

  .manual-override-element {
    padding: 15px 0;
    border-bottom: 1px solid $color-gray-extended-4;

    &__title {
      margin-bottom: 10px;
      font-family: $font-family-medium;
      font-size: 18px;
      line-height: 20px;
    }

    .selectbox {
      position: relative;
      margin-bottom: 10px;

      svg {
        width: 12px;
        fill: $color-gray-extended-2;
      }

      button {
        width: 100%;
      }

      ul {
        position: absolute;
        top: 63px;
        width: calc(100% - 2px);

        li {
          width: calc(100% - 2px);
        }
      }
    }

    .slide-in__link {
      margin: 10px 0 0;
    }

    &.fixture {
      border: 0;

      ul {
        top: 47px;
      }
    }
  }
}

// Specific style for LightsNetwork slide-in
.network {
  display: flex;

  &__content {
    .slide-in__title {
      height: 62px;
      margin: 0;
    }

    .slide-in__link {
      &.btn-container {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0;

        button {
          margin-left: auto;
        }
      }
    }

    .add-mg-top {
      margin-top: 5px;
    }

    .mg-top {
      > svg {
        transform: translate(0, -130%);
      }
    }
  }

  .modal {
    &__subtitle {
      margin-top: -25px;
      font-family: $font-family-medium;
    }

    &__primary-button {
      margin: 0;
    }

    .radio-buttons {
      flex-direction: column;
      justify-content: flex-start;
      margin-right: auto;

      &__element {
        margin-bottom: 10px;
      }
    }

    .textboxes {
      display: flex;
      flex-direction: column;

      label {
        color: $color-primary-black;
      }

      .selectbox__header {
        height: 40px;
      }

      &__timeboxes {
        display: flex;
        justify-content: space-between;
      }
    }

    .react-datepicker__label--above {
      font-family: $font-family-medium;
    }

    .table--light {
      height: 60vh;
    }
  }
}

// Specific style for LightsFirmware slide-in
.firmware {
  display: flex;

  .firmware-expanded {
    .slide-in__title {
      height: 62px;
      margin: 0;
    }

    .slide-in__content-title {
      margin-bottom: 10px;
      font-size: 18px;
    }

    .slide-in__content-subtitle {
      font-size: 14px;
    }

    &__information {
      display: grid;
      margin-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-gray-extended-4;
      color: $color-gray-extended-2;
      font-size: 12px;
      grid-template-columns: 50% 50%;
      grid-row-gap: 5px;

      > div:nth-child(odd) {
        font-weight: bold;
      }
    }

    &__subtitle {
      margin-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-gray-extended-4;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    .selectbox {
      position: relative;
      margin-bottom: 10px;

      svg {
        width: 12px;
        fill: $color-gray-extended-2;
      }

      ul {
        position: absolute;
        top: 47px;

        li {
          width: calc(100% - 2px);
        }
      }
    }
  }
}

.energy-multinode {
  position: relative;

  .slide-in__title {
    border: 0;
  }

  .slide-in__title-subtitle {
    justify-content: space-between;
    color: $color-gray-extended-2;

    svg {
      width: 12px;
      height: 12px;
      fill: $color-gray-extended-2;
    }

    .tooltip__element {
      width: 322px;
      height: 52px;
      font-family: $font-family-roman;
      white-space: normal;
    }
  }

  .slide-in__content {
    height: calc(100% - 80px);
  }

  .slide-in__link {
    margin: 0;
  }

  .sort {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .plot-sensors {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 302px;
    height: 500px;
    padding: 30px 50px;
    border: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-6;
    color: $color-primary-white;
    z-index: map-get($z-index, modal);

    &__close-button {
      position: absolute;
      top: 9px;
      right: 9px;
      border: 0;
      background: transparent;

      svg {
        width: 16px;
        height: 16px;
        fill: $color-primary-white;
      }
    }

    .slide-in__link {
      margin-top: 0;
    }
  }
}

.alarms, .alarms-multi {
  .no-alarms {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
  .slide-in {
    padding: 15px 0;

    &__title {
      padding: 0 20px 15px;
    }

    &__content {
      height: 100%;
      background-color: $color-gray-extended-6;

      > span {
        display: flex;
        justify-content: center;
        font-size: 10px;
        line-height: 12px;
        overflow: hidden;
      }
    }

    &__link {
      height: 15px;
      margin-bottom: 0;
    }

    .info-element--multi {
      padding: 10px 3px 10px 20px;
      border: 0;
      overflow: hidden;

      &:after {
        position: absolute;
        top: 0;
        right: 5px;
        left: 20px;
        width: calc(100% - 23px);
        height: 1px;
        background: $color-gray-extended-8;
        content: '';
      }

      button {
        padding: 0;
      }
    }

    .info-element-letterhead {
      margin-bottom: 5px;
      font-size: 12px;

      &__title {
       font-size: 12px;

        &__id {
          color: #FFBC3D;
          padding-left: 10px;
        }
        &__description {
          display: block;
          color: $color-gray-extended-2;
          margin-left: 5px;
          margin-top: 10px;
        }
      }
    }

    .info-element-content {
      min-height: 32px;

      span {
        width: 280px;
        min-height: 32px;
        padding-right: 5px;
        font-size: 12px;
        line-height: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
      }
    }

    .slide-in__actions {
      margin-top: 15px;
    }
  }

  .selectbox {
    .selectbox__header {
      height: 25px;
      padding-left: 0;
      border: 0;
      border-bottom: 1px solid $color-gray-extended-2;
      font-family: $font-family-medium;
      font-size: 14px;

      > svg {
        width: 12px;
        height: 12px;
      }
    }

    ul {
      position: absolute;
      top: 100%;
      z-index: map-get($z-index, selectbox);
    }
  }
}

// Specific style for Lights sensors slide-in
.sensors {
  .slide-in {
    overflow-y: auto;

    .slide-in__content-title {
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;
    }

    &__title-subtitle {
      &-node {
        width: 55px;

        &-num {
          margin-right: 2px;
          color: $color-secondary-yellow;
        }
      }

      &-nodelist {
        width: 100%;
        max-height: 62px;
        overflow-y: auto;

        span {
          display: flex;
          flex-direction: column;
          height: 13px;
          font-family: $font-family-roman;
        }
      }
    }

    .textbox {
      &.long,
      input {
        width: 220px;
      }
    }

    .react-datepicker__label--above,
    .selectbox__label {
      color: $color-gray-extended-2;
    }
  }

  .selectbox {
    margin-bottom: 10px;

    .selectbox__header {
      width: 100%;
    }

    .selectbox__label {
      font-family: $font-family-medium;
    }
  }

  .react-datepicker-container {
    .react-datepicker__label--above {
      font-family: $font-family-medium;
    }
  }

  .radio-buttons {
    margin-top: 15px;
  }

  .slide-in__link {
    margin-top: 10px;
  }
}

.power-multinode,
.firmware-multinode,
.lights-ids-multinode,
.network-multinode {
  display: flex;

  .slide-in__content {
    height: calc(100% - 80px);
  }

  .slide-in__link {
    margin: 10px 0 0 20px;
  }
}

.lights-ids-multinode {

  .slide-in__title {
    padding-bottom: 40px;
  }
}

.network-multinode {
  position: relative;

  .slide-in__title {
    border: 0;
  }

  .plot-sensors {
    position: absolute;
    top: 80px;
    right: 20px;
    bottom: 0;
    width: 317px;
    padding: 30px 62px;
    border: 1px solid $color-gray-extended-5;
    background: $color-table-cell-bg;
    z-index: 402;
  }

  .plot-sensors__title {
    color: $color-primary-white;
  }

  .plot-sensors__close-button {
    position: absolute;
    top: 10px;
    right: 4px;
    border: 0;
    background: transparent;

    svg {
      fill: $color-primary-white;
    }
  }
}

.firmware-multinode {
  .slide-in__title {
    padding-bottom: 40px;
    border: 0;
  }
}

.lights-ids {
  display: flex;

  .slide-in--expanded {
    padding-right: 0;

    .slide-in__input-container {
      margin-right: 3px;
      padding-right: 10px;
    }

    .slide-in__link {
      margin-right: 21px;
    }
  }

  .modal {
    &__subtitle {
      color: $color-primary-black;
      font-family: $font-family-roman;
      font-size: 14px;
    }

    &__footer {
      margin-top: 0;
    }

    &__primary-button {
      margin-left: 0;
    }
  }

  &__note {
    margin-top: 15px;

    .textbox.dark .textbox__input {
      height: 45px;
      border: 1px solid $color-gray-extended-5;
      border-bottom: 1px solid $color-gray-extended-2;
    }
  }
}

.power-multinode {
  position: relative;

  .slide-in__title {
    border: 0;
  }

  .plot-sensors {
    position: absolute;
    top: 80px;
    left: 138px;
    width: 302px;
    height: 500px;
    padding: 30px 50px;
    border: 1px solid $color-gray-extended-5;
    background-color: $color-gray-extended-6;
    color: $color-primary-white;
    z-index: map-get($z-index, modal);

    &__close-button {
      position: absolute;
      top: 9px;
      right: 9px;
      border: 0;
      background: transparent;
      color: $color-primary-white;

      svg {
        width: 16px;
        height: 16px;
        fill: $color-primary-white;
      }
    }

    .slide-in__link {
      margin-top: 0;
    }
  }
}

.alarm-playbook {
  .content-text {
    color: $color-primary-black;
  }
}
