/* stylelint-disable max-nesting-depth */

.modal-fullpage {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $color-primary-white;
  overflow: auto;
  z-index: map-get($z-index, scedule-modal);

  .schedule,
  .sensorchart {
    display: flex;
    justify-content: space-between;
    height: 80px;
    background: $color-primary-black;
    color: $color-primary-white;
    z-index: map-get($z-index, scedule-modal);

    &-btns {
      display: flex;
      justify-content: flex-end;
      margin: auto 0;

      &-save,
      &-delete {
        align-self: center;
        width: 80px;
        height: 40px;
        margin-right: 20px;
        border-radius: 100px;
        font-size: 14px;
        line-height: 17px;
      }

      &-delete {
        margin-right: 10px;
      }

      &-close {
        display: flex;
        height: 80px;
        padding: 30px auto;
        border: 0;
        border-left: 1px solid $color-gray-extended-4;
        color: $color-primary-white;

        svg {
          display: flex;
          align-self: center;
          width: 16.5px;
          height: 16.5px;
          margin: auto 30px;
          fill: $color-primary-white;
        }
      }

      &-csv,
      &-pdf {
        display: flex;
        width: 60px;
        height: 80px;
        padding: 30px auto;
        border: 0;
        border-left: 1px solid $color-gray-extended-4;
        background: $color-gray-extended-8;
        color: $color-primary-white;

        svg {
          display: flex;
          align-self: center;
          width: 16.5px;
          height: 16.5px;
          margin: auto 20px;
          fill: $color-primary-white;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      z-index: map-get($z-index, scedule-modal);

      &-title {
        margin: auto 30px;
        font-family: $font-family-bold;
        font-size: 32px;
        line-height: 30px;
      }

      &-toolbar {
        display: flex;
        width: 400px;
        border-left: 1px solid $color-gray-extended-4;

        &:last-of-type {
          border-right: 1px solid $color-gray-extended-4;
        }

        .dropdown {
          width: 100%;
          padding: 0;
          border: 0;
        }

        &__btn {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          padding: 23px 20px;
          border: 0;
          outline: 0;
          outline-color: 0;
          outline-style: 0;
          background: transparent;
          text-align: start;
          cursor: pointer;

          svg {
            width: 18px;
            height: 24px;
            margin-right: 15px;
            fill: $color-primary-white;
          }

          &-content {
            display: grid;
            font-family: $font-family-roman;
            font-size: 14px;
            line-height: 16px;

            &-label {
              margin-right: 10px;
              color: $color-gray-extended-2;
              grid-column: 1;

              .required {
                color: $color-primary-red;
              }
            }

            &-text {
              color: $color-gray-extended-4;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              grid-column: 2;

              &.filled {
                color: $color-secondary-yellow;
                font-family: $font-family-medium;
                line-height: 17px;
              }
            }
          }
        }

        &-edit {
          position: absolute;
          top: 80px;
          width: 400px;
          padding: 20px 30px;
          border: 1px solid $color-gray-extended-5;
          background: $color-primary-black;

          .textbox {
            input::placeholder {
              color: $color-gray-extended-4;
            }
          }

          .react-datepicker-container {
            .react-datepicker-wrapper {
              input {
                width: 238px;
              }
            }
          }

          &-footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;

            .btn-primary {
              margin-right: 10px;
            }

            .btn-secondary {
              margin-right: 0;
            }
          }
        }
      }
    }

    .delete-schedule-confirm {
      color: $color-primary-black;
    }

    .edit-schedule-confirm {
      color: $color-primary-black;
      font-size: 12px;

      &--title {
        font-size: 14px;
      }

      > div {
        display: flex;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        .bold {
          font-family: $font-family-medium;
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 5px;

          .icon-info {
            fill: $color-secondary-blue;
          }

          .icon-warning {
            fill: $color-secondary-orange;
          }
        }
      }
    }

    .toolbar-tabs__links-icons {
      height: auto;
      margin-right: 20px;
      border-right: 1px solid $color-gray-extended-4;
      border-left: 1px solid $color-gray-extended-4;

      svg {
        fill: $color-primary-white;
      }

      .fallback-driverlevel-edit {
        position: absolute;
        top: -4px;
        left: -58px;
        width: 235px;
        padding: 20px 30px 30px;
        border: 1px solid $color-gray-extended-5;
        background: $color-primary-black;
        cursor: default;

        &--title {
          margin-bottom: 15px;
          font-family: $font-family-bold;
          font-size: 18px;
          line-height: 20px;
        }

        &--form {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .textbox {
            width: 75px;
            margin-right: 20px;
            margin-bottom: 0;

            &__input {
              padding: 0 30px 0 15px;
            }

            &__icon {
              top: 13px;
              right: 15px;
              font-size: 14px;
            }
          }
        }

        &--note {
          color: $color-gray-extended-3;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .fallback-driverlevel-readonly {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      &--title {
        color: $color-gray-extended-2;
        font-size: 12px;
        line-height: 14px;
      }

      &--value {
        display: flex;
        color: $color-secondary-yellow;
        font-family: $font-family-medium;
        line-height: 17px;

        .tooltip {
          width: 12px;
          height: 12px;
          margin-left: 5px;
          font-family: $font-family-roman;
          cursor: pointer;

          &__element {
            display: none;
            width: 400px;
          }

          .tooltip__element.bottom {
            transform: translate(-320px, 50%);

            &:after,
            &:before {
              top: unset;
              right: 68px;
              bottom: 100%;
              left: unset;
              transform: rotateX(180deg);
            }
          }

          &:hover {
            .tooltip__element {
              display: block;
            }
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .sensorchart {
    &__content {
      &-toolbar {
        width: 300px;

        &-edit {
          width: 300px;
        }
      }

      &-title {
        width: 240px;
      }
    }
  }
}
