.alarmconfig {

  .alarmconfig-container {
    display: flex;
    position: relative;
    height: 100%;
    cursor: pointer;
    border: 1px solid $screen-background-1;
    background-color: $color-primary-white;
    box-shadow: 0 0 5px $color-list-shadow-box;
    color: #000;
    padding: 20px;

    &__children {
      width: 100%;
      border: 1px solid #D8DADA;
    }

    &__heading {
      width: 100%;
      background-color: $color-gray-extended-1;
      border-bottom:1px solid #D8DADA;
      padding: 20px;

      &-name {
        display: flex;
        flex-direction: column;
        font-family: $font-family-bold;
        font-size: 18px;
        margin-bottom: 10px;
      }
      &-title {
        display: flex;
        color:$color-gray-extended-4;
        font-size: 14px;
      }
    }

  }
}

.alarmconfig-interactions {
  height: 100%;
}

.alarmconfig-divided__leftcontent {
  width: 50%;
  float: left;
  padding: 30px;

  .textbox {
    flex-direction: row;
    margin: 8px 0;

    label {
      width: 160px;
      line-height: 20px;
    }

    .textbox__input {
      height: 20px;
    }
  }

  .selectNodesWrapper {
    color: $color-primary-black;
    font-family: $font-family-medium;
    font-size: 14px;

    .radio-buttons {
      flex-direction: column;
      padding-top: 10px;

      &__element{
        margin-top: 10px;
      }
    }

    .selectbox {
      margin-top: 10px;
      width: 332px;
    }

  }

}
.alarmconfig-divided__rightcontent {
  padding: 30px;
  float: left;
  width: 50%;

  .gracePeriodWrapper
   {
    display: flex;
    margin-bottom: 15px;

    > div:first-of-type {
      margin-top: 0;
      margin-left: 0;
    }

    > div:last-of-type {
      margin-top: 0;
    }

    .textbox {
      width: 127px;
      margin-left: 10px;
    }

    .gracePeriodUnits {
      position: relative;
      right: 30px;
      font-size: 14px;
      line-height: 80px;
    }

  }

  .applyToNodesWrapper {
    color: $color-primary-black;
    font-family: $font-family-medium;
    font-size: 14px;

    .radio-buttons {
      flex-direction: row;
      justify-content: normal;
      padding: 10px 0;

      &__element{
        margin-bottom: 10px;

        &:first-child > div.radio-buttons__element-label{
          margin-right: 10px;
        }

      }
    }
  }
  .alarmconfig__container-footer {
    width: 160px;
    text-align: right;
    margin: 30px 25px;
  }
}




