.slide-in {
  .list-element {
    position: relative;
    justify-content: row;
    padding: 10px 0;
    border-bottom: 1px solid $color-primary-black;

    .tooltip {
      display: inline-block;
      cursor: pointer;
    }

    &.small {
      border-bottom: 1px solid $color-gray-extended-8;

      .list-element-title {
        margin-bottom: 5px;
      }

      .list-element-footer {
        color: $color-secondary-yellow;

        span {
          color: $color-primary-white;
        }
      }

      .add-prop {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        font-size: 12px;
        line-height: 14px;

        span {
          margin-left: 5px;
          color: $color-secondary-yellow;
        }

        .slide-in__link {
          margin: 0;
        }
      }
    }

    &.light {
      padding: 10px 0 15px;
      border-bottom: 1px solid $color-gray-extended-4;
    }

    &.mg-top {
      margin-top: 5px;
    }

    &-title {
      margin-bottom: 2px;
      font-family: $font-family-medium;
      font-size: 14px;
      line-height: 16px;

      svg {
        margin-left: 5px;

        .a {
          stroke: none;
          fill: $color-gray-extended-3;
        }
      }
    }

    &-content {
      display: flex;
      margin-bottom: 2px;
      color: $color-secondary-yellow;
      font-size: 24px;

      span {
        padding: 0 5px 0 0;
        color: $color-secondary-yellow;
        font-family: $font-family-bold;
      }

      &.small {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    span {
      width: auto;
      margin: 0;

      &.custom-val {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;

        svg {
          height: 18px;
        }
      }
    }

    &__refresh {
      position: absolute;
      top: 50%;
      right: 0;
      height: 18px;
      transform: translate(0, -50%);
      fill: $color-primary-white;
      cursor: pointer;

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% {  transform: rotate(359deg); }
      }
      &.refreshLoading {
        animation: spin 2s linear infinite;
        cursor: default;
        pointer-events: none;
        top: 38%;
      }
    }

    &-footer {
      display: flex;
      color: $screen-background-1;
      font-size: 12px;
      line-height: 14px;

      span {
        margin: 0 5px 0 0;
        font-family: $font-family-medium;
      }
    }
  }
  &__content.network__content {
    > div:nth-child(1) {
      svg.refreshLoading {
        top: 20%;
      }
    }
  }
}
