/* stylelint-disable max-nesting-depth */
.toolbar {
  display: flex;
  flex-direction: column;
}

.toolbar-tabs {
  display: flex;
  flex-grow: 1;
  height: $toolbar-height;
  background: $color-primary-black;
  color: $color-primary-white;

  &__container {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    justify-content: space-between;

    &-title {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      margin: auto;
      padding-left: 30px;

      &-main {
        margin: 0 0 5px;
        font-family: $font-family-bold;
        font-size: 32px;
      }

      &-subtitle {
        display: flex;
        align-items: stretch;
        margin-right: 20px;
        color: $color-secondary-yellow;
        font-family: $font-family-medium;
        font-size: 14px;
      }
    }

    &-tabset,
    &-tabset-group {
      display: flex;
      position: relative;

      &-container {
        display: flex;

        &.last {
          border-right: $toolbar-border-style;

          .toolbar-tabs__container-tabset-btn {
            border-right: 0;
          }
        }

        &.filter-groups {
          .toolbar-tabs__container-tabset-btn-content {
            svg {
              width: 24px;
              height: 24px;
              margin-top: 10px;
            }
          }
        }
      }

      &-btn {
        display: flex;
        justify-content: center;
        padding: 0 20px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        color: $color-primary-white;
        cursor: pointer;

        &:first-child {
          border-left: $toolbar-border-style;
        }

        &:hover {
          border-bottom: 5px solid $color-primary-red;
        }

        &.active {
          border-bottom: 5px solid $color-primary-red;
          background: $color-gray-extended-7;

          &.no-highlight {
            border-bottom-color: $color-primary-black;
            background-color: $color-primary-black;
          }
        }

        &-content {
          display: grid;
          align-items: center;
          margin: auto;
          cursor: pointer;

          svg {
            width: 18px;
            height: 18px;
            margin: 0 10px 0 0;
            grid-row: 1;
            grid-column: 1;
            fill: $color-primary-white;

            &.attention {
              fill: $color-secondary-orange;
            }

            &.ok {
              fill: $color-secondary-green;
            }
          }

          &-title {
            font-family: $font-family-bold;
            font-size: 18px;
            line-height: 20px;
            grid-row: 1;
            grid-column: 2;
          }

          &-subtitle {
            font-family: $font-family-medium;
            font-size: 12px;
            line-height: 14px;
            grid-row: 2;
            grid-column: 2;

            &.light {
              color: $color-gray-extended-5;
            }

            &.selected {
              color: $color-secondary-orange;
            }

            &.colored {
              color: $color-gray-extended-2;
              font-family: $font-family-roman;

              span {
                color: $color-secondary-yellow;
                font-family: $font-family-bold;
              }
            }
          }
        }

        .toolbaritem {
          display: flex;
          min-width: 320px;

          &__field-section {
            display: flex;
            flex-direction: column;
            margin-top: 6px;
            margin-left: 12px;
            font-size: 14px;
          }

          &__field {
            display: flex;
            flex-direction: row;
            margin-bottom: 3px;
          }

          &__field.vertical {
            flex-direction: column;
            line-height: 130%;
          }

          &__field-name {
            width: 72px;
          }

          &__field-value {
            min-width: 100px;
            color: $color-secondary-yellow;
            font-family: $font-family-medium;
          }

          svg {
            display: inline-block;
            margin: auto 0;
            opacity: 1;
          }
        }
      }
    }

    &-tabset {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__links {
    display: flex;
    border-left: $toolbar-border-style;

    &-icons {
      display: flex;
      position: relative;
      justify-content: center;
      height: 100%;
      border-bottom: 5px solid $color-gray-extended-8;

      &-header {
        display: flex;
        justify-content: center;
        height: 100%;
        background: $color-gray-extended-8;
        cursor: pointer;

        div {
          display: flex;
          align-self: center;
          padding: 10px 20px;
          border-right: $toolbar-border-style;
        }

        svg {
          height: 18px;
          fill: $color-primary-white;
        }

        &.no-border {
          div {
            border: 0;
          }
        }

        &.last {
          width: 30px;
          border-left: $toolbar-border-style;

          div {
            padding: 10px;
            border: 0;
          }
        }
      }

      &-content {
        position: absolute;
        top: calc(100% + 4px);
        right: 0;
        z-index: map-get($z-index, dropdown);

        .dropdown {
          display: none;
          border: 0;
          border-top: $toolbar-border-style;
          box-shadow: 0 5px 10px $color-primary-black;

          &__title {
            font-size: 18px;
          }

          &__subtitle {
            .small {
              font-size: 10px;
            }
          }

          &__content {
            color: $color-gray-extended-2;
            font-size: 14px;

            &.divider {
              padding: 0 0 20px;
            }

            p {
              margin: 2px 0 0;
              font-size: 12px;
              white-space: nowrap;
            }

            &__data {
              display: flex;

              & div {
                display: flex;
              }

              &-main {
                align-items: center;
                padding: 0 10px 0 0;
                border-right: $toolbar-border-style;

                &:first-child {
                  margin-right: 0;
                }
              }
            }

            &.manual-override {
              min-width: 215px;
              padding: 0;

              .dropdown__content__data-main {
                width: 115px;
                border: 0;

                &:last-child {
                  width: 100px;
                  padding: 0;
                }
              }

              .textbox {
                display: flex;
                flex-direction: column;
                margin: 0 5px 0 0;
                border-bottom: 1px solid $color-gray-extended-2;

                &:last-child {
                  margin: 0;
                }

                &__input {
                  padding: 0 13px;
                  border: 1px solid $color-gray-extended-5;
                  border-bottom: 1px solid $color-gray-extended-2;
                  color: $color-primary-white;
                  font-size: 14px;

                  &::placeholder {
                    color: $color-gray-extended-4;
                  }
                }
              }
            }

            .dropdown-button {
              display: flex;
              position: relative;
              align-items: center;
              width: 100%;
              margin: 0;
              padding: 0;
              border: 0;
              outline: 0;
              background: transparent;
              color: $color-primary-white;
              font-family: $font-family-medium;
              font-size: 14px;
              line-height: 16px;
              white-space: nowrap;
              cursor: pointer;

              &.divider {
                padding-top: 5px;
                padding-bottom: 20px;
              }

              &.last {
                padding-top: 20px;
              }

              &.divider:after {
                display: block;
                position: absolute;
                right: -30px;
                bottom: 0;
                left: -30px;
                height: 1px;
                margin-top: 20px;
                background: $color-gray-extended-5;
                content: '';
              }

              svg {
                margin-right: 12px;
                fill: $color-primary-white;
              }

              &:hover {
                color: $color-gray-extended-2;

                svg {
                  fill: $color-gray-extended-2;
                }
              }
            }
          }

          &__footer {
            display: flex;
            justify-content: flex-end;

            .dropdown__primary-button {
              padding: 0;
              border: 0;
              border-bottom: 1px solid $color-secondary-blue-80;
              border-radius: 0;
              color: $color-secondary-blue-80;
              font-family: $font-family-medium;
              font-size: 14px;
              line-height: 1;
            }
          }
        }
      }

      &.active,
      &:hover {
        border-bottom: 5px solid $color-primary-red;

        .toolbar-tabs__links-icons-header {
          background: $color-gray-extended-6;
        }

        .toolbar-tabs__links-icons-content {
          .dropdown {
            display: block;
          }
        }
      }

      &.no-border {
        border: 0;

        .toolbar-tabs__links-icons-header {
          > div {
            border: 0;
          }
        }
      }

      &.no-bg {
        background: none;

        .toolbar-tabs__links-icons-header {
          background: none;
        }
      }
    }

    &-moreinfo {
      display: flex;
      height: 100%;
      border-left: $toolbar-border-style;
      background: $color-gray-extended-8;
      cursor: pointer;

      svg {
        display: flex;
        align-self: center;
        fill: $color-primary-white;
      }

      .modal,
      .modal__backdrop {
        cursor: default;
      }
    }
  }
}

.toolbar__subtoolbar {
  display: flex;
  background: $color-gray-extended-7;

  &-section {
    display: flex;
    overflow-x: auto;

    &.open {
      display: flex;
    }

    &.hide {
      display: none;
    }

    &.scheduledon {
      height: 50px;
    }

    &.scheduledon,
    &.attention {
      .toolbar__subtoolbar-section-item {
        display: flex;
        align-items: center;
        padding: 0 30px;
        border-right: $toolbar-border-style;
        border-bottom: 5px solid transparent;
        color: $color-primary-white;
        font-size: 14px;
        cursor: pointer;

        span {
          font-family: $font-family-medium;
          user-select: none;
        }

        svg {
          margin: 0 10px 0 0;
          fill: $color-primary-white;
        }

        &:hover {
          border-bottom: 5px solid $color-primary-red;
        }

        &.active {
          border-bottom: 5px solid $color-primary-red;
          background: $color-gray-extended-6;

          svg {
            fill: $color-primary-white;
          }
        }
      }
    }

    &.attention {
      height: 60px;
      white-space: nowrap;

      .toolbar__subtoolbar-section-item {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        div {
          display: flex;
          align-self: end;
          color: $color-primary-white;
          font-family: $font-family-bold;
          font-size: 18px;
        }

        span {
          align-self: center;
          user-select: none;
        }

        .title {
          display: block;
          min-width: 75px;
          margin: 5px 0 0;
          color: $color-primary-white;
          font-size: 12px;
          overflow-wrap: break-word;
        }

        svg {
          fill: $color-gray-extended-4;
        }

        &:hover {
          svg {
            fill: $color-primary-white;
          }
        }
      }
    }
  }
}

.toolbaritem-jobs-filter,
.toolbaritem-jobs-sort {
  .toolbar-tabs__container-tabset-btn.active {
    border-bottom-color: $color-primary-black;
    background-color: $color-primary-black;
  }
}

.toolbaritem-jobs-filter {
  .toolbar-tabs__container-tabset-btn {
    width: 100%;
  }
}

.toolbaritem-jobs-sort {
  width: 230px;

  .toolbaritem__field-name {
    width: 50px;
  }

  .toolbar-tabs__container-tabset-btn {
    width: 100%;

    .toolbaritem {
      min-width: 190px;
    }
  }
}

.toolbaritem-alarms-daterange {
  width: 280px;
  font: $font-family-medium;
  font-size: 14px;

  .toolbaritem {
    min-width: 240px;
  }

  .toolbaritem__field {
    align-items: center;

    &-name {
      width: 26px;
      margin-right: 8px;
    }
  }

  .active {
    border-top: none;
    border-bottom: none;
  }
}

.toolbaritem-alarms-duration {
  width: 302px;
  font: $font-family-medium;
  font-size: 14px;

  .toolbaritem {
    min-width: 260px;
  }

  .toolbaritem__field {
    align-items: center;

    &-name {
      width: 70px;
      min-width: 70px;
      margin-right: 8px;
    }
  }

  .active {
    border-top: none;
    border-bottom: none;
  }
}

.toolbaritem-alarms-filtergroups {
  position: absolute;
  right: 100px;
  width: 230px;
  height: 80px;
  border-right: 1px solid $color-gray-extended-5;
  font: $font-family-medium;
  font-size: 14px;

  .toolbar-tabs__container-tabset-btn-content-subtitle {
    font-size: 14px;
  }
}

.toolbar-alarms-active .toolbaritem,
.toolbar-alarms-all .toolbaritem {
  width: 72px;
  min-width: 72px;
  text-align: center;
}

.toolbar-alarms-active .toolbaritem {
  position: relative;
  left: 12px;
}

.toolbar-alarms-all .toolbaritem {
  position: relative;
  left: 26px;
}

.alarms {
  .toolbar-tabs__container-tabset-btn:hover {
    border-bottom-color: $color-gray-extended-7;
  }
}

.schedule-toolbar {
  .dropdown-lighting-group-list {
    .ReactVirtualized__Grid.ReactVirtualized__List {
      border: 0;
    }
  }
}
